import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import UploadImg from "../../assets/upload_file.png";
import { upload } from "@testing-library/user-event/dist/upload";
import { Spin } from "antd";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { getData } from "../../Utilities";
import { generateReport, gettingReportFromServer, uploadFileToVirusTotal } from "../../api";
import Alert from "@mui/material/Alert";
import axios from "axios";
import UseLocalStorage from "../../hooks/localstorage.hook";
import "./uploadFile.styles.css";
import { toast } from "react-toastify";

const { Dragger } = Upload;
const props = {
    name: "file",
    multiple: false,
};
function UploadFile({ setData }) {
    const [hash, setHash] = useState("");
    const [visibleImg, setVisibleImg] = useState(true);
    const [scanImg, setScanImg] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [successAlert, setSuccessAlert] = React.useState(true);
    const [alertContent, setAlertContent] = React.useState({});
    const [uploadFileStatus, setUploadFileStatus] = useState(false)

    const handleScanNew = () => {
        setThreat(-1);
        setScanImg(false);
        setName("");
        // setEmail("");
        setAlertContent({});
        setVisibleImg(true);
        // setEmailEntered(false);
        setCheckFile(false);
        setOriginalFile(null);
        setShowloadinBox(false);
        setShowResultBox(false);
        setUploadFileStatus(false)
    };
    const [threat, setThreat] = useState(-1);
    const [emailEntered, setEmailEntered] = useState(false);
    const [checkFile, setCheckFile] = useState(false);
    const [originalFile, setOriginalFile] = useState(null);
    const [showLoadingBox, setShowloadinBox] = useState(false);
    const [showResultBox, setShowResultBox] = useState(false);
    const [disabledTime, setDisabledTime, removeDisabledTime] = UseLocalStorage('disabledTime', null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [lastClickedTime, setLastClickedTime, removeLastClickedTime] = UseLocalStorage("lastClickedTime", null)
    const [disabled, setDisabled, removeDisabled] = UseLocalStorage('buttonDisabled', false);
    const handleFileChange = async (file) => {
        console.log(file.file.originFileObj, "<=== file")
        const maxSizeInBytes = 650 * 1024 * 1024;
        if (file.file.size > maxSizeInBytes) {
            toast.error("File size exceeds the 650 MB limit")
            return; // Return if file size exceeds 650 MB
        }
        if (!file)
            return
        setOriginalFile(file.file)
        setCheckFile(true)
        setName(file.file.name)
    };

    const onCancelUpload = () => {
        setCheckFile(false);
        setEmailEntered(true)
    }
    const onFileUpload = async () => {
        setLastClickedTime(new Date().getTime())
        setDisabled(true);
        setShowloadinBox(true)
        // setCheckFile(false)
        try {
            const response = await uploadFileToVirusTotal(originalFile, email);
            if (response === undefined) {
                toast.error("something went wrong while fetching your data")
                return
            }
            else {
                setData(response)
                setThreat(threat + response?.attributes?.stats?.malicious + response?.attributes?.stats?.suspicious)
                setShowResultBox(true)
                setShowloadinBox(false)
            }


        } catch (e) {
            console.log(e, "<==============")
            // alert("error while uploading data to the server")
        }
        // console.log(response.attributes.stats.malicious, "<=== wow");
    }

    const LoadingBox = () => {
        return <div
            style={{
                border: "1px solid #FF0000 ",
                backgroundColor: "White",
                borderRadius: ".75rem",
                height: "100%",
            }}
            className="d-flex py-5 justify-content-center align-items-center"
        >
            <div className="text-center ">
                <div>
                    <h1 className="text-danger">
                        <strong>Scanning...</strong>
                    </h1>
                    <br />
                    <Spin size="large" className="custom-spinner" />
                </div>
                <div>
                    <br />
                    {/* <h3>Too many scans are currently in progress!</h3> */}
                    {uploadFileStatus ? <h3>Please wait while your file is being scanned...</h3> : <h3>Please wait while your file is being uploaded, it may take a while...</h3>}
                </div>
            </div>
        </div>
    }

    const CompletedBox = () => {
        return <div
            style={{
                // backgroundColor:
                //     alertContent.severity === "error" ? "#f5ecec" : "#caebdb",
                borderRadius: ".75rem",
                height: "100%",
            }}
            className="d-flex py-5 justify-content-center align-items-center"
        >
            <div className="text-center ">
                {threat > -1 ? (
                    <div>
                        <h3 className="text-danger">
                            <strong>Potential Threats Found</strong>
                        </h3>
                    </div>
                ) : <div> <h3 className="text-success">
                    <strong>No Threats Detected</strong>
                </h3></div>}
                <div>
                    <br />
                    <h3> Successful</h3>
                    <p>kindly check your email inbox for detailed report</p>
                    <br />

                    <button
                        style={{ backgroundColor: "red", borderColor: "red" }}
                        className="btn btn-primary"
                        onClick={handleScanNew}
                    >
                        Scan New File
                    </button>
                </div>
            </div>
        </div>
    }


    useEffect(() => {
        if (disabled) {
            const now = new Date().getTime();
            // setDisabledTime(now);
            const timer = setInterval(() => {
                const elapsedTime = now - lastClickedTime;
                const timeLeft = 180000 - elapsedTime;
                if (timeLeft <= 0) {
                    setDisabled(false);
                    removeDisabledTime();
                    setRemainingTime(null);
                    removeLastClickedTime();
                    clearInterval(timer);
                } else {
                    setRemainingTime(timeLeft);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [disabled, disabledTime, setDisabledTime, removeDisabledTime]);
    return (
        <>
            {emailEntered ? null : <div className="text-center ">
                <div>
                    <br />
                    {/* {threat === 1 && <h3> Safe to Use or Not?</h3>} */}
                    <h3> Enter your email to check and scan your file </h3>
                    <br />

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        setEmailEntered(true)
                    }} >
                        <div className="d-flex justify-content-around">
                            <TextField
                                label="Email"
                                id="outlined-size-small"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // size="small"
                                style={{ width: "70%" }}
                                // fullWidth
                                required
                                type="email"
                            />
                            <Button
                                variant="contained"
                                className="btn btn-primary"
                                style={{ backgroundColor: "#FF0000" }}
                                type="submit"
                                // style={{
                                //     backgroundColor: "white",
                                //     border: "1px solid black",
                                //     color: "black",
                                // }}
                                disabled={loader}
                            >
                                {loader ? <Spin size="medium" /> : "Upload File"}
                            </Button>
                        </div>
                    </form>
                    <br />
                    {/* <button
                    className="btn btn-primary rounded-pill"
                    onClick={handleScanNew}
                >
                    Upload File
                </button> */}
                </div>
            </div>}
            {emailEntered && checkFile === false ? <Dragger style={{ color: "#FF0000", borderColor: "#FF0000", borderStyle: "none" }} {...props} onChange={handleFileChange} listType="none" multiple={false} showUploadList={{ showRemoveIcon: false }} >
                <div className="d-flex justify-content-around">
                    <img src={UploadImg} alt="box uploading" style={{ backgroundColor: "#FF0000", color: "#FF0000" }} />
                    <div className="mt-3">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined style={{ color: "#FF0000" }} />
                        </p>

                        <p className="ant-upload-hint">Max File Size of 650 MB.</p>
                        <p className="ant-upload-text">
                            Drop your files to scan for viruses
                        </p>
                        <button style={{ background: "#FF0000", borderColor: "#FF0000" }} className="btn btn-primary">Choose File</button>
                    </div>
                </div>
            </Dragger> : null}
            {checkFile && showLoadingBox === false && showResultBox === false ? <div
                style={{
                    border: "1px solid #FF0000 ",
                    borderRadius: ".75rem",
                    height: "100%",
                }}
                className="d-flex py-5 justify-content-center align-items-center"
            >
                <div className="text-center">
                    <div>
                        <h5>
                            <strong>File Selected For Scanning</strong>
                        </h5>
                        <p>{name}</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button
                            className="btn  mx-2"
                            style={{
                                backgroundColor: "white",
                                color: "black",
                                border: "1px solid black",
                            }}
                            onClick={onCancelUpload}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary mx-2"
                            style={{ backgroundColor: " #FF0000", borderColor: "#FF0000" }}
                            disabled={disabled}
                            onClick={onFileUpload}
                        >
                            Scan now
                        </button>

                    </div>
                    {disabled && remainingTime !== null && (
                        <p> Time left to scan again: {Math.ceil(remainingTime / 1000)} seconds</p>
                    )}

                </div>
            </div> : null}
            {showLoadingBox ? <LoadingBox /> : null}
            {showResultBox && !showLoadingBox ? <CompletedBox /> : null}
        </>
    )
}

export default UploadFile