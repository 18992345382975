import axios from "axios";
import { getData, holdData } from "../Utilities";
import { toast } from "react-toastify";

const apiKey = process.env.REACT_APP_API_KEY;
const serverURL = process.env.REACT_APP_API_URL;
const virusTotalKey = process.env.REACT_APP_VIRUS_TOTAL_API_KEY;
const generateIdInVirusTotal = (hash) => {
  return fetch(`${process.env.REACT_APP_API_URL}generateResult/${hash}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Headers": "*",
    },
  });
};

const getResponseById = (api) => {
  return fetch(api, {
    method: "GET",
    headers: {
      "x-apikey": apiKey,
      "Access-Control-Allow-Headers": "*",
    },
  });
};

const generateReport = (reportContent) => {
  return fetch(`${process.env.REACT_APP_API_URL}generateReport/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reportContent),
  });
};

const uploadFileToVirusTotal = async (data, email) => {
  try {
    const formData = new FormData();
    formData.append('file', data.originFileObj);
    formData.append("email", email);
    // Replace with your server endpoint
    const response = await axios.post(serverURL + "report/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response
  } catch (e) {
    console.log(e)
    toast.error(e.data.message ? e.data.message : "something went wrong while upladoing your file")
  }
}

const gettingReportFromServer = async (id, email) => {
  try {
    const result = await sendDetails(id, email);
    holdData(result)
    return result;
  } catch (e) {
    console.log(e)
  }
}


const sendDetails = async (id, email) => {
  try {
    const res = await axios.post(serverURL + "report/", { id, email }, { headers: { "Access-Control-Allow-Origin": "*" } });
    return res.data.data
  } catch (e) {
    console.log(e)
    console.log(e.message, "<====")
    console.log(e, "<=== error while getting data from server")
  }
}

export { generateIdInVirusTotal, getResponseById, generateReport, uploadFileToVirusTotal, gettingReportFromServer };
