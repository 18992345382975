import { useState } from 'react';

// Custom hook for working with localStorage
const UseLocalStorage = (key, initialValue) => {
    // Get initial value from localStorage if available, otherwise use initialValue
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(`Error retrieving localStorage item ${key}: ${error}`);
            return initialValue;
        }
    });

    // Function to set a value in localStorage
    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(`Error setting localStorage item ${key}: ${error}`);
        }
    };

    // Function to remove a value from localStorage
    const removeValue = () => {
        try {
            setStoredValue(undefined);
            window.localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing localStorage item ${key}: ${error}`);
        }
    };

    return [storedValue, setValue, removeValue];
};

export default UseLocalStorage;